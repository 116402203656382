
import React, { useState } from "react";
import { useEffect } from 'react';
import "./comingSoon.css";
import { Link } from 'react-router-dom';


function ComingSoon() {

  useEffect(() => {
    const element = document.documentElement;
    element.style.scrollBehavior = 'smooth';
    element.scrollTo(0, 0);
  }, []);
  
 
  return (
    <>
      <div>
  
        <section >
           <div className="HotelArea">

        <div className="Header" style={{backgroundColor: 'none'}}>
              <ol>
                <li className="dropdown">
                  <a href="#" id="navbardrop" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Connect with us</a>
                  <ol className="dropdown-menu">
                    <li>
                      <a href="#">India Office : Stay Connected Digitech Pvt Ltd. A-211,Sector 122, Noida 201301</a>
                    </li>
                    <li>
                      <a href="mailto:connect@bettermystay.com">connect@bettermystay.com</a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            

      <h5>Coming Soon</h5>
      <div className="HotelBox">
        <h4>Get Notified When be Launch</h4>
        <br/>
        <form id="signup-form">
          <input type="email" id ="email" placeholder="Enter your email address" required />
          <button id="submit">Notify Me</button>
        </form>
        <br/>

        <p>* Don't worry we will not spam you :)</p>
      </div>


      <div className="FooterMain">
     
        <div class="footer">
          <p>
          <Link
            to="/TermsAndConditions"
            spy={true}
            smooth="true"
            offset={-100}
            duration={500}
          >
            TERMS AND CONDITIONS
          </Link>
          </p>
          <p>|</p>
          <p>
          <Link
            to="/PrivacyPolicy"
            spy={true}
            smooth="true"
            offset={-100}
            duration={500}
          >
            PRIVACY POLICY
          </Link>
          </p>  
        </div>

        <br/>
        <p>
          BetterMyStay ™ is the trade name of Stay Connected Digitech Pvt. Ltd, a company registered in India under the companies Act, 2013
        </p>

        
      </div>
      <div className="FooterMain">

        
      </div>
    
    </div>
          
        </section>
       
      </div>
    </>
  );
}

export default ComingSoon;
